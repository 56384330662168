import { useParams } from "next/navigation"

import { BrandedPageParams } from "@supernovaio/cloud/app/[locale]/[wsId]/[dsId]/[versionId]/[brandId]/types"
import { normalizeParams } from "@supernovaio/cloud/utils/routing/normalizeParams"

import { getReadableRoute } from "../../utils/routing/getReadableRoute"
import { BrandRouteType } from "../../utils/routing/types"
import { useBrand } from "../data/useBrand"
import { useDesignSystem } from "../data/useDesignSystem"
import { useVersion } from "../data/useVersion"
import { useWorkspace } from "../data/useWorkspace"

/** Use get url to different wsId, dsId, versionId, brandId then actual route */
export function useBrandRoute(
  wsId: string | undefined,
  dsId: string | undefined,
  versionId: string | undefined,
  brandId: string | undefined
): BrandRouteType | undefined {
  const { data: workspace } = useWorkspace(wsId, false)
  const { data: designSystem } = useDesignSystem(dsId)
  const { data: version } = useVersion(dsId, versionId)
  const { data: brand } = useBrand(dsId, versionId, brandId)

  if (!workspace || !designSystem || !version || !brand) return undefined

  return getReadableRoute(workspace, designSystem, version, brand)
}

export function useCurrentBrandRoute() {
  // original params with name, e.g. wsId: wsId-wsName
  const params = useParams<BrandedPageParams>()

  const normalizedParams = normalizeParams(params)

  const brandRoute = useBrandRoute(
    normalizedParams.wsId,
    normalizedParams.dsId,
    normalizedParams.versionId,
    normalizedParams.brandId
  )

  if (!params.wsId || !params.dsId || !params.versionId || !params.brandId) {
    return undefined
  }

  if (!brandRoute) {
    return `/${params.wsId}/${params.dsId}/${params.versionId}/${params.brandId}`
  }

  // if fetched return brand route. It is kept there for consistency and in case of titles of parts in url are wrong - e.g. renamed paged
  return brandRoute
}
